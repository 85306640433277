import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('tokenSmartWearBd');
        const userToken = JSON.parse(tokenString);
        return userToken?.tokenSmartWearBd
      };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('tokenSmartWearBd', JSON.stringify(userToken));
    setToken(userToken.tokenSmartWearBd);
  };

  return {
    setToken: saveToken,
    token
  }

}
