import React from 'react';
import underConstruction from '../assets/gif/underConstruction.webp';

export const Home = () => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <img src={underConstruction} alt="smart wear bd" />
      </div>
    </>
  );
};
