import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoSettingsOutline, IoWatchSharp } from 'react-icons/io5';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaUserTie,
} from 'react-icons/fa';

export const Sidebar = ({ isExpanded, toggleSidebar }) => {
  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  return (
    <nav id="sidebar" className={isExpanded ? 'active' : ''}>
      <div className="sidebar-header d-flex">
        <div className="user pt-3">
          <FaUserTie className="user" />
        </div>
        <button className="btn btn-toggle" onClick={toggleSidebar}>
          <span className="icon">
            {isExpanded ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
          </span>
        </button>
      </div>

      <ul className="list-unstyled components pt-5">
        <li
          className={
            location.pathname === '/dashboard/jewellery-smartwatch-gift-box'
              ? 'active'
              : ''
          }
        >
          <Link to="/dashboard/jewellery-smartwatch-gift-box">
            <IoWatchSharp />
            Luxury Jewellery Smartwatch Gift Box
          </Link>
        </li>
        <li
          className={
            location.pathname === '/dashboard/hk9-ultra-2' ? 'active' : ''
          }
        >
          <Link to="/dashboard/hk9-ultra-2">
            <IoWatchSharp />
            KH9 Ultra 2
          </Link>
        </li>

        <li>
          <Link to="#">
            <IoSettingsOutline />
            Settings
          </Link>
        </li>
      </ul>

      <button
        className="login logout mb-3 mt-5 mb-5"
        onClick={() => {
          handleLogout();
        }}
      >
        LOGOUT
      </button>
    </nav>
  );
};
