import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const Customers = () => {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const getAllCustomers = async () => {
      const tokenData = JSON.parse(
        localStorage.getItem('tokenSmartWearBd'),
      ).tokenSmartWearBd;
      try {
        const res = await axios.get(
          `https://smartwearbd.com/app/allCustomers?params=${tokenData}`,
        );
        setCustomers(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCustomers();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  return (
    <>
      <div className="container-fluid">
        <button onClick={handleLogout} className="login mb-3">
          LOGOUT
        </button>
        <table className="table table-striped table-bordered mb-5 mt-3">
          <thead className="table-dark">
            <tr>
              <th>Date Time</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>District</th>
              <th>Shipping</th>
              <th>Model Name</th>
              <th>Total Price</th>
            </tr>
          </thead>

          <tbody>
            {customers.map((customer) => (
              <tr>
                <td> {customer.order_datetime} </td>
                <td> {customer.name} </td>
                <td> {customer.email} </td>
                <td> {customer.phone} </td>
                <td> {customer.address} </td>
                <td> {customer.district} </td>
                <td> {customer.shipping} </td>
                <td> {customer.model_name} </td>
                <td> {customer.total_price} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Customers;
