import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Slider } from './slider/Slider';
import { Review } from './review/Review';
import { ShortFeatures } from './short-features/ShortFeatures';
import { GetDistrictsUnfiltered } from 'districts-of-bangladesh';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#f39220' : 'default_color',
  }),
};

export const HK9ULTRA2 = () => {
  const navigate = useNavigate();
  const modelName = 'HK9 ULTRA 2';

  const [selectedOption, setSelectedOption] = useState('outsideDhaka');
  const [totalAmount, setTotalAmount] = useState(2970);
  const [customer, setCustomer] = useState({
    name: '',
    phone: '',
    address: '',
    district: '',
    shipping: selectedOption,
    modelName: modelName,
  });
  const [inputValidation, setInputValidation] = useState({
    name: true,
    phone: true,
    address: true,
    district: true,
  });
  const [isFormCleared, setIsFormCleared] = useState(false);
  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(false);

  const handleInputChange = (e) => {
    setCustomer((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setInputValidation((prev) => ({ ...prev, [e.target.name]: true }));
  };

  const addCustomer = async () => {
    try {
      await axios.post('https://smartwearbd.com/app/addCustomer', customer);
      // console.log('Order confirmed with total amount:', totalAmount);
      setIsOrderButtonDisabled(true);
      navigate('/thank-you');
    } catch (err) {
      console.log(err);
      alert(
        'Website is facing an issue please order through phone call or WhatsApp 01938388879',
      );
    }
  };

  const orderButtonRef = useRef();

  // clearForm
  useEffect(() => {
    if (isFormCleared) {
      setCustomer({
        name: '',
        phone: '',
        address: '',
        district: '',
        shipping: selectedOption,
        modelName: modelName,
      });
      setInputValidation({
        name: true,
        phone: true,
        address: true,
        district: true,
      });
      setTotalAmount(2970);
      setIsFormCleared(false);
    }
  }, [isFormCleared, selectedOption]);

  const clearForm = () => {
    setIsFormCleared(true);
  };

  //countdown timer
  const initialCountdownTime = 3 * 60 * 60;
  const [countdownTime, setCountdownTime] = useState(
    parseInt(localStorage.getItem('countdownTime'), 10) || initialCountdownTime,
  );
  const [timerInterval, setTimerInterval] = useState(null);

  const updateCountdownTime = () => {
    setCountdownTime((prevTime) => prevTime - 1);
  };

  useEffect(() => {
    localStorage.setItem('countdownTime', countdownTime.toString());

    if (countdownTime <= 0) {
      clearInterval(timerInterval);
      alert('Countdown complete!');
    }
  }, [countdownTime, timerInterval]);

  useEffect(() => {
    const intervalId = setInterval(updateCountdownTime, 1000);
    setTimerInterval(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const hours = Math.floor(countdownTime / 3600);
  const minutes = Math.floor((countdownTime % 3600) / 60);
  const seconds = countdownTime % 60;

  // confirm order button
  const orderFormRef = useRef();

  const handleOrderConfirm = () => {
    const isNameValid = customer.name.trim() !== '';
    const isPhoneValid = /^\d{11}$/.test(customer.phone);
    const isAddressValid = customer.address.trim() !== '';
    const isDistrictValid = customer.district.trim() !== '';

    setInputValidation({
      name: isNameValid,
      phone: isPhoneValid,
      address: isAddressValid,
      district: isDistrictValid,
    });

    if (!(isNameValid && isPhoneValid && isAddressValid && isDistrictValid)) {
      return;
    }

    //console.log(customer);
    addCustomer();
    clearForm();
    orderFormRef.current.reset();
  };

  // shipping option
  const handleOptionChange = (event) => {
    const { value, name } = event.target;
    setSelectedOption(value);
    setCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === 'outsideDhaka') {
      setTotalAmount(2970 + 120);
    } else if (value === 'insideDhaka') {
      setTotalAmount(2970 + 80);
    }
  };

  // scroll
  const scrollToOrderNow = () => {
    orderButtonRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  // districts name
  const districtOptions = GetDistrictsUnfiltered().map((district) => ({
    value: district,
    label: district,
  }));

  return (
    <>
      <div className="container-fluid pe-5 ps-5">
        <h1 className="text-capitalize text-center">HK9 ULTRA 2</h1>
        <h4 className="text-center pb-3">Multi- functional smart watch</h4>

        {/* slider */}
        <Slider />
        {/* স্মার্ট পিপলদের জন্যই স্মার্ট গেজেট */}
        <div className="text-center pt-3 pb-5">
          <h1 className="pb-2">স্মার্ট পিপলদের জন্যই স্মার্ট গেজেট</h1>

          <h4>
            ১০% ডিসকাউন্ট বাদে এখন মাত্র <strong>২৯৭০ টাকা</strong>
          </h4>

          <button className="orderNow pt-2" onClick={scrollToOrderNow}>
            এখনই অর্ডার করুন
          </button>
        </div>
      </div>

      <div className="video">
        <iframe
          width="100%"
          height="450"
          src="https://www.youtube.com/embed/4OVQYLFIy1g?si=6SNgkBDIbkrFbo1M"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="container-fluid pe-5 ps-5">
        <div className="video-content text-center pt-3 pb-3">
          <p>
            <b>যা করতে পারবেন</b>
            <ul>
              <li>
                Wearfit pro এর সাথে কানেক্ট করে অসংখ্য গেমস, ওয়ালপেলার, ফিচার
                উপভোগ করা
              </li>
              <li>ব্লাড রেট, হার্ট বিট জানা</li>
              <li>গান শোনা, কল করা এবং রিসিভ করা</li>
              <li>কম্পাস ব্যবহার করে সঠিক দিক দেখা</li>
              <li>চ্যাট জিপিটি ব্যবহার করে নানান তথ্য বের করা</li>
            </ul>
          </p>

          <button className="orderNow" onClick={scrollToOrderNow}>
            এখনই অর্ডার করুন
          </button>
        </div>

        {/* review */}
        <Review />

        <div className="discount-time d-flex justify-content-between pt-5 pb-5">
          <h1>ডিসকাউন্ট শেষ হয়ে যাবে</h1>

          <p>
            {hours} ঘন্টা, {minutes} মিনিট, {seconds} সেকেন্ড এর মধ্যে
          </p>
        </div>
      </div>

      {/* আপনার প্রতিদিনের পার্সোনাল */}
      <div className="wrapper text-center d-flex flex-column justify-content-center">
        <h1>আপনার প্রতিদিনের পার্সোনাল এবং হেলথ অ্যাসিস্ট্যান্ট</h1>
        <button className="orderNow mb-3" onClick={scrollToOrderNow}>
          এখনই অর্ডার করুন
        </button>
      </div>

      <div className="container-fluid pe-5 ps-5">
        <ShortFeatures />

        <div className="order-now">
          <div className="text-center">
            <h1>এখনই অর্ডার করুন</h1>

            <p>প্রোডাক্ট এর মূল্য আপনি হাতে পেয়ে পরিশোধ করতে পারবেন।</p>

            <p>
              তবে শুধু ঢাকা সিটির বাইরে ডেলিভারি চার্জ ১২০ টাকা বিকাশে/নগদে
              এডভান্স করতে হবে।
            </p>
          </div>

          {/* Billing & Shipping */}
          <div className="row pt-3">
            <div className="col-md-6 col-sm-12">
              <form ref={orderFormRef}>
                <h3>Billing & Shipping</h3>

                <div className="form pt-3">
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      সম্পূর্ণ নাম *
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        !inputValidation.name && 'invalid'
                      }`}
                      id="fullName"
                      placeholder="সম্পূর্ণ নাম"
                      onChange={handleInputChange}
                      name="name"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="mobileNumber" className="form-label">
                      মোবাইল ফোন নাম্বার (১১ ডিজিটের) *
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        !inputValidation.phone && 'invalid'
                      }`}
                      id="mobileNumber"
                      placeholder="মোবাইল ফোন নাম্বার"
                      onChange={handleInputChange}
                      name="phone"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      সম্পূর্ণ ঠিকানা * - রোড নাম্বার/ ইউনিয়ন/ বাসা নাম্বার সহ
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        !inputValidation.address && 'invalid'
                      }`}
                      id="address"
                      placeholder="সম্পূর্ণ ঠিকানা"
                      onChange={handleInputChange}
                      name="address"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="district" className="form-label">
                      জেলা *
                    </label>

                    <Select
                      options={districtOptions}
                      className={`form-control ${
                        !inputValidation.district && 'invalid'
                      }`}
                      placeholder="জেলা"
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: 'district',
                            value: selectedOption.value,
                          },
                        })
                      }
                      isSearchable
                      name="district"
                      required
                      styles={customStyles}
                    />
                  </div>
                </div>
              </form>
            </div>

            {/* Your order */}
            <div className="col-md-6 col-sm-12">
              <h3>Your order</h3>
              <table className="table pt-3">
                <thead>
                  <tr>
                    <th colSpan="2">Product</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>HK9 ULTRA 2</td>
                    <td>× 1</td>
                    <td>৳ 2,970</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colSpan="2">Shipping</td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          className="form-check-input"
                          value="outsideDhaka"
                          checked={selectedOption === 'outsideDhaka'}
                          name="shipping"
                          onChange={handleOptionChange}
                        />
                        <span className="ps-2 sm-f-1">ঢাকার বাহিরে: ৳ 120</span>
                      </label>
                      <br />
                      <label>
                        <input
                          type="radio"
                          className="form-check-input"
                          value="insideDhaka"
                          checked={selectedOption === 'insideDhaka'}
                          name="shipping"
                          onChange={handleOptionChange}
                        />
                        <span className="ps-2 text-wrap sm-f-1">
                          ঢাকা সিটির ভিতরে: ৳ 80
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="fw-bold">
                      Total
                    </td>
                    <td className="fw-bold">৳ {totalAmount}</td>
                  </tr>
                </tbody>
              </table>

              <button
                className="confromOrder w-100 text-center pt-3 pb-3"
                onClick={() => {
                  handleOrderConfirm();
                }}
                ref={orderButtonRef}
                disabled={isOrderButtonDisabled}
              >
                অর্ডার কনফার্ম করুন ৳ {totalAmount}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
