import React, { useState } from 'react';
import { BiChevronsRight } from 'react-icons/bi';
import watch from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/1.jpg';

export const ShortFeatures = () => {
  const [showMore, setShowMore] = useState(false);

  const handleSeeMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="short-features pt-5 pb-5">
      <h1>সংক্ষেপে লাক্সারি জুয়েলারি স্মার্ট ওয়াচ গিফট বক্সটির ফিচারগুলো</h1>

      <div className="row align-items-center pb-5">
        <div className="col-md-6 col-sm-12">
          <hr />
          <p>
            <BiChevronsRight /> DISPLAY: 2.09 inch, TFT
          </p>
          <p>
            <BiChevronsRight /> WATCH SIZE: 45mm:44*38*10.7mm
          </p>
          <p>
            <BiChevronsRight /> PANEL: 60 HZ
          </p>
          <p>
            <BiChevronsRight /> RESOLUTION: 325*380
          </p>
          {/* <p>
            <BiChevronsRight /> AOD SYSTEM: 24 hour always on
          </p> */}
          <p>
            <BiChevronsRight /> BATTERY: 200mah
          </p>
          <p>
            <BiChevronsRight /> Os: Android 5.0 or above and ios 9.0 above
          </p>
          <p>
            <BiChevronsRight /> IP67 waterproof
          </p>
          {/* <p>
            <BiChevronsRight /> Storage: 2GB
          </p> */}
          <p>
            <BiChevronsRight /> Battery Life: Up to- 5 days regular use
          </p>
          <p>
            <BiChevronsRight /> 10 days replacement warranty
          </p>
          {/* {showMore && (
            <>
              <p>
                <BiChevronsRight /> Strap: orange,black
              </p>
              <p>
                <BiChevronsRight /> CONTROL: Crown Hill button middle mouth
                speaker,customize sport button
              </p>
              <p>
                <BiChevronsRight /> Bluetooth: 5.1 / connection reliable
              </p>
              <p>
                <BiChevronsRight /> PROCESSOR : Dual core process, Firmware
                update
              </p>
              <p>
                <BiChevronsRight /> Charging: Wireless
              </p>
              <p>
                <BiChevronsRight /> Materials: Metal
              </p>

              <p>
                <BiChevronsRight /> Belt Material: Silicone
              </p>

            </>
          )}
          <button className="see-more-btn" onClick={handleSeeMoreClick}>
            {showMore ? 'See Less' : 'See More'}
          </button> */}
        </div>
        <div className="col-md-6 col-sm-12">
          <img src={watch} alt="HK9 ULTRA 2" />
        </div>
      </div>

      <h1 className="pt-5 pb-2">সংক্ষেপে জুয়েলারি ব্রেসলেটের ফিচারগুলো</h1>
      <div className="row align-items-center">
        <div className="col-md-6 col-sm-12">
          <p>
            <BiChevronsRight /> Luxurious metallic body jewelry watch bracelet
          </p>
          <p>
            <BiChevronsRight /> Lightweight and comfortable to wear
          </p>
          <p>
            <BiChevronsRight /> The unique design looks so gorgeous
          </p>
          <p>
            <BiChevronsRight /> Strong built quality
          </p>
          <p>
            <BiChevronsRight /> Unique crystal component used with metallic
            strap
          </p>
        </div>
        <div className="col-md-6 col-sm-12">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/rx4U3U7TMZw?si=ovwN2zKUVD4UPOZ4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};
