import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Sidebar } from './dist/Sidebar';
import { DashboardContent } from './dist/DashboardContent';
import { WelcomeMessage } from './WelcomeMessage';

export const DashBoard = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  return (
    <div
      className={`dashboard mb-5 ${
        isSidebarExpanded ? 'sidebar-expanded' : ''
      }`}
    >
      <Sidebar isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
      <Routes>
        <Route
          path="/jewellery-smartwatch-gift-box"
          element={
            <DashboardContent title="Luxury Jewellery Smartwatch Gift Box" />
          }
        />
        <Route
          path="/hk9-ultra-2"
          element={<DashboardContent title="HK9 Ultra 2" />}
        />
        <Route path="/" element={<WelcomeMessage />} />{' '}
        {/* Add this Route for '/' */}
      </Routes>
    </div>
  );
};
