import React, { useState, useEffect } from 'react';

const Pagination = ({ length, postsPerPage, currentPage, paginationSet }) => {
  const [activePage, setActivePage] = useState(currentPage);

  const paginationNumbers = [];

  for (let i = 1; i <= Math.ceil(length / postsPerPage); i++) {
    paginationNumbers.push(i);
  }

  useEffect(() => {
    setActivePage(currentPage);
  }, []);

  const handlePaginationClick = (pageNumber) => {
    setActivePage(pageNumber);
    paginationSet(pageNumber);
  };

  return (
    <div className="bg-primary-subtle d-flex justify-content-center p-2 rounded-3 pagination">
      {paginationNumbers.map((pageNumber) => (
        <button
          className="m-1 page-item bg-transparent d-flex"
          key={pageNumber}
          onClick={() => handlePaginationClick(pageNumber)}
        >
          <span
            className={`page-link me-3 border-0 ${
              activePage === pageNumber ? 'active' : ''
            }`}
          >
            {pageNumber}
          </span>
        </button>
      ))}
    </div>
  );
};

export default Pagination;
