import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import slider1 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/11.jpg';
import slider2 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/1.jpg';
import slider3 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/2.jpg';
import slider4 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/3.jpg';
import slider6 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/5.jpg';
import slider7 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/6.jpg';
import slider8 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/7.jpg';
import slider9 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/8.jpg';
import slider10 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/9.jpg';
import slider11 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/10.jpg';
import slider12 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/12.jpg';
import slider13 from '../../../assets/watch/JewellerySmartwatchGiftBox/slider/13.jpg';

export const Slider = () => {
  return (
    <div className="hero">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        speed={1200}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          200: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={slider1} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider2} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider3} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider4} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>

        <SwiperSlide>
          <img src={slider6} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider7} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider8} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider9} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider10} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider11} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider12} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider13} alt="Luxury Jewellery Smartwatch Gift Box" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
