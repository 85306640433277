import React from 'react';
import orderProcessing from '../assets/gif/orderProcessing.gif';
export const ThankYou = () => {
  return (
    <div className="container text-center pt-3 pb-5">
      <h1 className="pt-5">আপনার অর্ডারটি গ্রহণ করা হয়েছে।</h1>
      <div className="gif">
        <img src={orderProcessing} alt="orderProcessing" />
      </div>
      <h2 className="pt-3 pb-5">ধন্যবাদ, আমাদের উপর আস্থা রাখার জন্য।</h2>
    </div>
  );
};
