import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import slider1 from '../../../assets/watch/HK9ULTRA2/slider/11.jpg';
import slider2 from '../../../assets/watch/HK9ULTRA2/slider/01.jpg';
import slider3 from '../../../assets/watch/HK9ULTRA2/slider/02.jpg';
import slider4 from '../../../assets/watch/HK9ULTRA2/slider/03.jpg';
import slider5 from '../../../assets/watch/HK9ULTRA2/slider/04.jpg';
import slider6 from '../../../assets/watch/HK9ULTRA2/slider/05.jpg';
import slider7 from '../../../assets/watch/HK9ULTRA2/slider/06.jpg';
import slider8 from '../../../assets/watch/HK9ULTRA2/slider/07.jpg';
import slider9 from '../../../assets/watch/HK9ULTRA2/slider/08.jpg';
import slider10 from '../../../assets/watch/HK9ULTRA2/slider/09.jpg';
import slider11 from '../../../assets/watch/HK9ULTRA2/slider/10.jpg';

export const Slider = () => {
  return (
    <div className="hero">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        speed={1200}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          200: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={slider1} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider2} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider3} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider4} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider5} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider6} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider7} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider8} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider9} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider10} alt="HK9 ULTRA 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slider11} alt="HK9 ULTRA 2" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
