import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HK9ULTRA2 } from './components/HK9 ULTRA 2/HK9ULTRA2';
import { ThankYou } from './components/ThankYou';
import logo from '../src/assets/logo/logo.png';
import { JewellerySmartwatchGiftBox } from './components/JewellerySmartwatchGiftBox/JewellerySmartwatchGiftBox';
import Customers from './components/Customers';
import Login from './components/Login';
import useToken from './components/useToken';
import { Home } from './components/Home';
import { DashBoard } from './components/Dashboard/DashBoard';

export const App = () => {
  const { token, setToken } = useToken();

  return (
    <>
      <h1 className="pt-5 pb-3">
        <img src={logo} className="m0Auto" alt="Logo of Smart wear BD" />
      </h1>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/step/hk9-ultra-2" element={<HK9ULTRA2 />} />
          <Route
            path="/step/jewellery-smartwatch-gift-box"
            element={<JewellerySmartwatchGiftBox />}
          />
          <Route
            path="/main/allCustomers"
            element={token ? <Customers /> : <Login setToken={setToken} state={'/main/allCustomers'}/>}
          />
          <Route path="/thank-you" element={<ThankYou />} />
          
          <Route path="/dashboard/*" 
          element={token ? <DashBoard /> : <Login setToken={setToken} state={'/dashboard'}/>} />

        </Routes>
      </BrowserRouter>

      <p className="mb-5 text-center">
        &copy; <strong>Smart wear bd</strong> {new Date().getFullYear()} All
        rights reserved
      </p>
    </>
  );
};
