import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';

export const DashboardContent = ({ title }) => {
  const [customers, setCustomers] = useState([]);
  const [totalRowNumbersResult, setTotalRowNumbersResult] = useState([]);
  const [rowViewStart, setRowViewStart] = useState(0);
  const modelName = { title };

  const numberOfView = 10; //number of data showing per page
  const paginationSet = (pageNumber) => {
    setRowViewStart((pageNumber - 1) * 10);
  };

  console.log(rowViewStart);

  useEffect(() => {
    const getAllCustomers = async () => {
      const tokenData = localStorage.getItem('tokenSmartWearBd');
      if (!tokenData) {
        console.error('Token not found in localStorage');
        return;
      }

      try {
        const res = await axios.get(
          'https://smartwearbd.com/app/allCustomers',
          {
            params: {
              token: JSON.parse(tokenData).tokenSmartWearBd,
              model: modelName.title,
              rowViewStart: rowViewStart,
              numberOfView: numberOfView,
            },
          },
        );
        console.log(res.data);
        setCustomers(res.data.data);
        setTotalRowNumbersResult(res.data.totalRowNumbersResult);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCustomers();
  }, [modelName.title, rowViewStart]);

  return (
    <div id="content">
      <h2>{title}</h2>

      <table className="table table-striped table-bordered mb-5 mt-3">
        <thead className="table-dark">
          <tr>
            <th>Sl.</th>
            <th>Date Time</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>District</th>
            <th>Shipping</th>
            <th>Model Name</th>
            <th>Total Price</th>
          </tr>
        </thead>

        <tbody>
          {customers.map((customer, i) => (
            <tr key={customer.id}>
              <td> {customer.id} </td>
              <td>{new Date(customer.order_datetime).toLocaleString()} </td>
              <td> {customer.name} </td>
              <td> {customer.email} </td>
              <td> {customer.phone} </td>
              <td> {customer.address} </td>
              <td> {customer.district} </td>
              <td> {customer.shipping} </td>
              <td> {customer.model_name} </td>
              <td> {customer.total_price} </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination
        length={totalRowNumbersResult}
        postsPerPage={10}
        paginationSet={paginationSet}
      ></Pagination>
    </div>
  );
};
