import React from 'react';

export const WelcomeMessage = () => {
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center vh-100 border ms-3 my-3 mt-0 mb-0">
      <div className="text-center">
        <h2>Welcome to the Dashboard!</h2>
        <p>This is your dashboard home page.</p>
      </div>
    </div>
  );
};
