import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Slider } from './slider/Slider';
import { Review } from './review/Review';
import { ShortFeatures } from './short-features/ShortFeatures';
import { GetDistrictsUnfiltered } from 'districts-of-bangladesh';
import Select from 'react-select';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import ReactFbq from 'react-fbq';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#f39220' : 'default_color',
  }),
};

ReactFbq.initialize({
  id: '2680581632098637',
  token:
    'EAANPWnb6h5kBO2ivfk96cSqSVQ9NuP0ZAHVfKNqcPRAQdkOP0C9rUnr8UMZC2k6meBSZCzZChoFDnElZAtny7ttiQ1f9BZB4aZBtNJ9qPjz5k5JZBMHTxuVM6NmPJScZBTeFQzX7zWhH3wRdZAixK8GmWHN00zr6cLztoLXbfIIiX9nCAt3tiK4DdNZCWXgLI9wTbYZC8AZDZD',
});
ReactFbq.pageView();

export const JewellerySmartwatchGiftBox = () => {
  const navigate = useNavigate();
  const modelName = 'Luxury Jewellery Smartwatch Gift Box';
  const [shippingAlertShown, setShippingAlertShown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [totalAmount, setTotalAmount] = useState(2490);
  const [customer, setCustomer] = useState({
    name: '',
    phone: '',
    address: '',
    district: '',
    shipping: selectedOption,
    modelName: modelName,
  });
  const [inputValidation, setInputValidation] = useState({
    name: true,
    phone: true,
    address: true,
    district: true,
  });
  const [isFormCleared, setIsFormCleared] = useState(false);
  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(false);
  const [phoneError, setPhoneError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const phoneNumber = value.replace(/\D/g, '');
      const isPhoneValid = phoneNumber.length === 11;

      setCustomer((prev) => ({
        ...prev,
        [name]: phoneNumber,
      }));

      setInputValidation((prev) => ({
        ...prev,
        [name]: isPhoneValid,
      }));

      if (phoneNumber.length < 11) {
        setPhoneError('মোবাইল নাম্বারটি ১১ ডিজিটের হতে হবে!');
      } else if (phoneNumber.length > 11) {
        setPhoneError('মোবাইল নাম্বারটি ১১ ডিজিটেরের বেশি হতে পারবে না!');
      } else {
        setPhoneError('');
      }
    } else {
      setCustomer((prev) => ({ ...prev, [name]: value }));
      setInputValidation((prev) => ({ ...prev, [name]: true }));
    }
  };

  const addCustomer = async () => {
    try {
      await axios.post('https://smartwearbd.com/app/addCustomer', customer);
      ReactFbq.track('Purchase', { value: totalAmount, currency: 'BDT' });
      setIsOrderButtonDisabled(true);
      navigate('/thank-you');
    } catch (err) {
      console.log(err);
      alert(
        'Website is facing an issue please order through phone call or WhatsApp 01938388879',
      );
    }
  };

  const orderButtonRef = useRef();

  // clearForm
  useEffect(() => {
    if (isFormCleared) {
      setCustomer({
        name: '',
        phone: '',
        address: '',
        district: '',
        shipping: selectedOption,
        modelName: modelName,
      });
      setInputValidation({
        name: true,
        phone: true,
        address: true,
        district: true,
      });
      setTotalAmount(2490);
      setIsOrderButtonDisabled(!selectedOption);
      setIsFormCleared(false);
    }
  }, [isFormCleared, selectedOption]);

  const clearForm = () => {
    setIsFormCleared(true);
  };

  //countdown timer
  const initialCountdownTime = 3 * 60 * 60;
  const [countdownTime, setCountdownTime] = useState(
    parseInt(localStorage.getItem('countdownTime'), 10) || initialCountdownTime,
  );
  const [timerInterval, setTimerInterval] = useState(null);

  const updateCountdownTime = () => {
    setCountdownTime((prevTime) => prevTime - 1);
  };

  useEffect(() => {
    localStorage.setItem('countdownTime', countdownTime.toString());

    if (countdownTime <= 0) {
      clearInterval(timerInterval);
      alert('Countdown complete!');
    }
  }, [countdownTime, timerInterval]);

  useEffect(() => {
    const intervalId = setInterval(updateCountdownTime, 1000);
    setTimerInterval(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const hours = Math.floor(countdownTime / 3600);
  const minutes = Math.floor((countdownTime % 3600) / 60);
  const seconds = countdownTime % 60;

  // confirm order button
  const orderFormRef = useRef();

  const handleOrderConfirm = () => {
    const isNameValid = customer.name.trim() !== '';
    const isPhoneValid = /^\d{11}$/.test(customer.phone);
    const isAddressValid = customer.address.trim() !== '';
    const isDistrictValid = customer.district.trim() !== '';

    setInputValidation({
      name: isNameValid,
      phone: isPhoneValid,
      address: isAddressValid,
      district: isDistrictValid,
    });

    const isShippingOptionSelected = selectedOption !== '';
    if (
      !(
        isNameValid &&
        isPhoneValid &&
        isAddressValid &&
        isDistrictValid &&
        isShippingOptionSelected
      )
    ) {
      if (!shippingAlertShown) {
        const shippingCell = document.getElementById('shippingCell');
        if (shippingCell) {
          shippingCell.innerHTML =
            '<div class="alert alert-danger shipping-alert">সবগুলো অপশন ফিলাপ সম্পন্ন করা হয়নি।</div>' +
            shippingCell.innerHTML;
          setShippingAlertShown(true);
        }
      }
      return;
    }

    addCustomer();
    clearForm();
    orderFormRef.current.reset();
    ReactFbq.track('OrderConfirmed', { totalAmount: totalAmount });
    ReactFbq.track('InitiateCheckout', { totalAmount: totalAmount });
  };

  // shipping option
  const handleOptionChange = (event) => {
    const { value, name } = event.target;
    setSelectedOption(value);
    setCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === 'outsideDhaka') {
      setTotalAmount(2490 + 120);
    } else if (value === 'insideDhaka') {
      setTotalAmount(2490 + 60);
    }

    const shippingCell = document.getElementById('shippingCell');
    if (shippingCell) {
      const shippingAlert = shippingCell.querySelector('.shipping-alert');
      if (shippingAlert) {
        shippingAlert.remove();
      }
    }

    setShippingAlertShown(false);
  };

  // scroll
  const scrollToOrderNow = () => {
    orderButtonRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    ReactFbq.track('ScrollToOrder', { section: 'OrderNow' });
  };

  // districts name
  const districtOptions = GetDistrictsUnfiltered().map((district) => ({
    value: district,
    label: district,
  }));

  return (
    <>
      <div className="container-fluid pe-5 ps-5">
        <h1 className="text-capitalize text-center">
          Luxury Jewellery Smartwatch Gift Box
        </h1>
        <h4 className="text-center pb-3 pt-3">Multi- functional smartwatch</h4>
        {/* slider */}
        <Slider />
        {/* Story */}
        <div className="col-12 pt-5 story">
          <h4>
            <FaQuoteLeft className="mb-3 me-3" />
            আমরা যখন প্রিয়জনকে কিছু উপহার দেই তখন অনেকসময় একটা গুরুত্বপূর্ণ বিষয়
            মাথায় রাখা হয়ে ওঠেনা। তা হলো- এই উপহার টা শুধুই কি একটা নিছক উপহার?
            এক বা দুইবার ব্যবহার করে রেখে দিবে বা শো-কেসে সাজিয়ে রাখবে এমন কিছু?
            <br />
            <br />
            নাকি আমরা চাই প্রিয়জনকে এমন কোন উপহার দিতে; যা সে বারবার নিজের
            প্রয়োজনে ব্যবহার করবে এবং আমাদের সাথে কাটানো আনন্দঘন মূহুর্তের কথা
            স্মরণ করবে! <br />
            <br />
            সত্যি! এমন উপহারগুলোকেই বেস্ট উপহার বলা যায়।
            <br />
            <br />
            তাই Smart Wear BD একটা ইউনিক ব্রেসলেট সহ স্মার্ট ওয়াচের এই লাক্সারি
            গিফট বক্স কনসেপ্ট নিয়ে এসেছে। যাতে একই সাথে উপহার পাওয়ার আনন্দে
            বিমোহিত হওয়া যায় এবং ডেইলি লাইফে স্মার্ট গ্যাজেট ব্যবহার করে লাইফে
            নতুনত্বও আনা যায়। আর এরই সাথে সাথে নিয়মিত প্রিয়জনের সাথে কাটানো
            সোনালী মূহুর্তগুলো রোমন্থন করা যায়।
            <FaQuoteRight className="ms-3" />
          </h4>
        </div>
        {/* স্মার্ট পিপলদের জন্যই স্মার্ট গেজেট */}
        <div className="text-center pt-5 pb-5">
          <h1 className="pb-2">স্মার্ট পিপলদের জন্যই স্মার্ট গেজেট</h1>

          <h4 className="pb-3">
            ৩৩% ডিসকাউন্টে এখন মাত্র <strong>২৪৯০ টাকা</strong>
          </h4>

          <button className="orderNow pt-2 mb-3" onClick={scrollToOrderNow}>
            এখনই অর্ডার করুন
          </button>
        </div>
        <div className="row">
          <div className="video pb-5 pt-3 col-md-6 col-sm-12">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/1dkIo3sB0eo?si=DXPqqOZDk-avl_rN"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video pb-5 pt-3 col-md-6 col-sm-12">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/4zLYhSWo0sQ?si=NF4Go3vpHut-Nwvm"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className="container-fluid pe-5 ps-5">
        <div className="video-content text-center pt-3 pb-3">
          <p>
            <b>স্মার্টওয়াচে যা যা করতে পারবেন</b>
            <ul>
              <li>
                Fitpro App এর সাথে কানেক্ট করে অসংখ্য গেমস, ওয়ালপেলার, ফিচার
                উপভোগ করা
              </li>
              <li>ব্লাড রেট, হার্ট বিট জানা</li>
              <li>গান শোনা, কল করা এবং রিসিভ করা</li>
              <li>কম্পাস ব্যবহার করে সঠিক দিক দেখা</li>
            </ul>
          </p>

          <button className="orderNow mt-3 mb-5" onClick={scrollToOrderNow}>
            এখনই অর্ডার করুন
          </button>
        </div>

        {/* review */}
        <Review />

        <div className="discount-time d-flex justify-content-between pt-5 pb-5">
          <h1>ডিসকাউন্ট শেষ হয়ে যাবে</h1>

          <p>
            {hours} ঘন্টা, {minutes} মিনিট, {seconds} সেকেন্ড এর মধ্যে
          </p>
        </div>
      </div>

      {/* আপনার প্রতিদিনের পার্সোনাল */}
      <div className="wrapper text-center d-flex flex-column justify-content-center">
        <h1 className="mt-3">
          আপনার প্রতিদিনের পার্সোনাল এবং হেলথ অ্যাসিস্ট্যান্ট
        </h1>
        <button className="orderNow mt-2 mb-5" onClick={scrollToOrderNow}>
          এখনই অর্ডার করুন
        </button>
      </div>

      <div className="container-fluid pe-5 ps-5">
        <ShortFeatures />

        <div className="order-now">
          <div className="text-center">
            <h1>এখনই অর্ডার করুন</h1>

            <p>প্রোডাক্ট এর মূল্য আপনি হাতে পেয়ে পরিশোধ করতে পারবেন।</p>

            <p>
              তবে শুধু ঢাকা সিটির বাইরে ডেলিভারি চার্জ ১২০ টাকা বিকাশে/নগদে
              এডভান্স করতে হবে।
            </p>
          </div>

          {/* Billing & Shipping */}
          <div className="row pt-3">
            <div className="col-md-6 col-sm-12">
              <form ref={orderFormRef}>
                <h3>Billing & Shipping</h3>

                <div className="form pt-3">
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      সম্পূর্ণ নাম *
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        !inputValidation.name && 'invalid'
                      }`}
                      id="fullName"
                      placeholder="সম্পূর্ণ নাম"
                      onChange={handleInputChange}
                      name="name"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="mobileNumber" className="form-label">
                      মোবাইল ফোন নাম্বার (১১ ডিজিটের) *
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        !inputValidation.phone && 'invalid'
                      }`}
                      id="mobileNumber"
                      placeholder="মোবাইল ফোন নাম্বার"
                      onChange={handleInputChange}
                      name="phone"
                      required
                    />
                    {phoneError && (
                      <div className="text-danger pt-3">{phoneError}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      সম্পূর্ণ ঠিকানা * - রোড নাম্বার/ ইউনিয়ন/ বাসা নাম্বার সহ
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        !inputValidation.address && 'invalid'
                      }`}
                      id="address"
                      placeholder="সম্পূর্ণ ঠিকানা"
                      onChange={handleInputChange}
                      name="address"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="district" className="form-label">
                      জেলা *
                    </label>

                    <Select
                      options={districtOptions}
                      className={`form-control ${
                        !inputValidation.district && 'invalid'
                      }`}
                      placeholder="জেলা"
                      onChange={(selectedOption) =>
                        handleInputChange({
                          target: {
                            name: 'district',
                            value: selectedOption.value,
                          },
                        })
                      }
                      isSearchable
                      name="district"
                      required
                      styles={customStyles}
                    />
                  </div>
                </div>
              </form>
            </div>

            {/* Your order */}
            <div className="col-md-6 col-sm-12">
              <h3>Your order</h3>
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th colSpan="2">Product</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td name="modelName">
                      Luxury Jewellery Smartwatch Gift Box
                    </td>
                    <td>× 1</td>
                    <td>৳ 2490</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colSpan="2" id="shippingCell">
                      Shipping
                    </td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          className="form-check-input"
                          value="outsideDhaka"
                          checked={selectedOption === 'outsideDhaka'}
                          name="shipping"
                          onChange={handleOptionChange}
                          required
                        />
                        <span className="ps-2 sm-f-1">ঢাকার বাহিরে: ৳ 120</span>
                      </label>
                      <br />
                      <label>
                        <input
                          type="radio"
                          className="form-check-input"
                          value="insideDhaka"
                          checked={selectedOption === 'insideDhaka'}
                          name="shipping"
                          onChange={handleOptionChange}
                          required
                        />
                        <span className="ps-2 text-wrap sm-f-1">
                          ঢাকা সিটির ভিতরে: ৳ 60
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="fw-bold">
                      Total
                    </td>
                    <td className="fw-bold">৳ {totalAmount}</td>
                  </tr>
                </tbody>
              </table>

              <button
                className="confromOrder w-100 text-center pt-3 pb-3"
                onClick={() => {
                  handleOrderConfirm();
                }}
                ref={orderButtonRef}
                disabled={isOrderButtonDisabled}
              >
                অর্ডার কনফার্ম করুন ৳ {totalAmount}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
