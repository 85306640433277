import React, { useState } from 'react';
import { BiChevronsRight } from 'react-icons/bi';
import watch from '../../../assets/watch/HK9ULTRA2/slider/11.jpg';

export const ShortFeatures = () => {
  const [showMore, setShowMore] = useState(false);

  const handleSeeMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="short-features pt-5 pb-5">
      <h1>সংক্ষেপে অত্যাধুনিক স্মার্ট ওয়াচটির ফিচারগুলো</h1>

      <div className="row align-items-center">
        <div className="col-md-6 col-sm-12">
          <hr />
          <p className="pt-3">
            <BiChevronsRight /> DISPLAY: 2.0 INCH,amoled
          </p>
          <p>
            <BiChevronsRight /> PANEL: 60 HZ
          </p>
          <p>
            <BiChevronsRight /> RESOLUTION: 485×520
          </p>
          <p>
            <BiChevronsRight /> AOD SYSTEM: 24 hour always on
          </p>
          <p>
            <BiChevronsRight /> BATTERY: 380mah
          </p>
          <p>
            <BiChevronsRight /> Os: Android 4.4 or above and ios 10.0 above
          </p>
          <p>
            <BiChevronsRight /> Waterproof,Dustproof
          </p>
          <p>
            <BiChevronsRight /> Storage: 2GB
          </p>
          {showMore && (
            <>
              <p>
                <BiChevronsRight /> Strap: orange,black
              </p>
              <p>
                <BiChevronsRight /> CONTROL: Crown Hill button middle mouth
                speaker,customize sport button
              </p>
              <p>
                <BiChevronsRight /> Bluetooth: 5.1 / connection reliable
              </p>
              <p>
                <BiChevronsRight /> PROCESSOR : Dual core process, Firmware
                update
              </p>
              <p>
                <BiChevronsRight /> Charging: Wireless
              </p>
              <p>
                <BiChevronsRight /> Materials: Metal
              </p>
              <p>
                <BiChevronsRight /> Battery Life: Up to- 5-7 days regular use &
                24 hours while always on display
              </p>
              <p>
                <BiChevronsRight /> Belt Material: Silicone
              </p>
              <p>
                <BiChevronsRight /> 10 days replacement warranty
              </p>
            </>
          )}
          <button className="see-more-btn" onClick={handleSeeMoreClick}>
            {showMore ? 'See Less' : 'See More'}
          </button>
        </div>
        <div className="col-md-6 col-sm-12">
          <img src={watch} alt="HK9 ULTRA 2" />
        </div>
      </div>
    </div>
  );
};
