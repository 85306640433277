import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const Login = ({ setToken, state }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const loginUser = async (credentials) => {
    try {
      const res = await axios
        .post('https://smartwearbd.com/app/admin/login', credentials, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((res) => {
          setToken(res.data);
          if (res.data.tokenSmartWearBd === 'smart-token-00452') {
            navigate(state);
          } else {
            setIsModalOpen(true);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;
    const token = await loginUser({
      username,
      password,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="container-fluid d-flex justify-content-center pb-5 mb-5">
        <div className="login-wrapper p-5">
          <h1 className="text-center pb-3">Please Log In</h1>
          <form onSubmit={handleSubmit} className="">
            <div>
              <label>
                <p>Username</p>
                <input type="text" name="username" className="form-control" />
              </label>
            </div>
            <div>
              <label>
                <p>Password</p>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                />
              </label>
            </div>

            <div className="text-center pt-5">
              <button className="login" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Order Confirmation Modal"
          className="orderConfirmModal"
        >
          <h2 style={{ color: 'red' }}>WARNING! NO PERMIT</h2>
          {/* <button className="me-5" onClick={handleConfirmModal}>
                    OK
                </button> */}
          <button onClick={handleCloseModal}>OK</button>
        </Modal>
      </div>
    </>
  );
};

export default Login;

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
