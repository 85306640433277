import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import review1 from '../../../assets/watch/JewellerySmartwatchGiftBox/review/review1.png';
import review2 from '../../../assets/watch/JewellerySmartwatchGiftBox/review/review2.png';
import review3 from '../../../assets/watch/JewellerySmartwatchGiftBox/review/review3.png';
import review4 from '../../../assets/watch/JewellerySmartwatchGiftBox/review/review4.png';
import review5 from '../../../assets/watch/JewellerySmartwatchGiftBox/review/review5.png';
import review6 from '../../../assets/watch/JewellerySmartwatchGiftBox/review/review6.png';
import review7 from '../../../assets/watch/JewellerySmartwatchGiftBox/review/review7.png';

export const Review = () => {
  return (
    <div className="review">
      <h1 className="pt-5 pb-5">আমাদের কাস্টোমার রিভিউ</h1>

      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        speed={1200}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={review7} alt="HK9 ULTRA 2 review" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={review6} alt="HK9 ULTRA 2 review" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={review5} alt="HK9 ULTRA 2 review" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={review4} alt="HK9 ULTRA 2 review" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={review1} alt="HK9 ULTRA 2 review" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={review2} alt="HK9 ULTRA 2 review" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={review3} alt="HK9 ULTRA 2 review" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
